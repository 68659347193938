
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { handleLogout } from "../helper/actions";
import NotifyBackInStockModal from "../components/modals/NotifyBackInStockModal";

const getToken = () => localStorage.getItem("customerToken") ? localStorage.getItem("customerToken") : null;
const getQuoteId = () => localStorage.getItem("quoteId") ? localStorage.getItem("quoteId") : null;

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_MAGENTO_BASE_URL,

  prepareHeaders: (headers, { getState }) => {
    const token = getToken();
    const quoteId = getQuoteId();
    if (token) {
      headers.set('X-Customer-Token', token);
    }

    if (quoteId) {
      headers.set('quoteId', quoteId);
    }
    headers.set('Authorization', `Bearer ${process.env.REACT_APP_MAGENTO_API_TOKEN}`);
    return headers;
  }

});
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
 
  if ((result?.error && result?.error?.status === 401) || result?.status === 401) {

    handleLogout();
    localStorage.setItem("Session", "To access this content, please log in to your account.");

  } else {
    return result;

  }
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "parts",
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    newsLetterApi: builder.mutation({
      query: (value) => {
        return {
          url: "/rest/V1/newsletter/custom-subscribe",
          method: "POST",
          body: value,
        };
      },
    }),
    getPages: builder.query({
      query: (page) => `/rest/V1/cms/pages/${page}`,
    }),
    getFaqBuyers: builder.mutation({
      query: (value) => {
        return {
          url: "/rest/V1/faq/collection",
          method: "POST",
          body: value,
        };
      },
    }),
    getGalleryImages: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/image-gallery",
          method: "POST",
          body: values,
        };
      },
    }),
    getMyImages: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/dashboard/view/my-images",
          method: "POST",
          body: values,
        };
      },
    }),
    getGalleryVideos: builder.query({
      query: ({ limit, page }) => `/rest/V1/customer/video-gallery/${limit}/${page}`,

    }),
    getCategoryInfo: builder.query({
      query: (values) => {
        return {
          url: "/rest/V1/category-banner",
          method: "POST",
          body: values,
        };
      },
    }),
    singleCategoryData: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/category-productslist",
          method: "POST",
          body: values,
        };
      },
    }),
    getFrequentlyPurchased: builder.query({
      query: () => `/rest/V1/frequent-parts/4`,

    }),
    categoryFilters: builder.query({
      query: (values) => {
        return {
          url: "/rest/V1/custom-filters",
          method: "POST",
          body: values,
        };
      },
    }),
    getTrainingVideos: builder.query({
      query: ({ limit, page }) => `/rest/V1/customer/training-videos/${limit}/${page}`,
    }),

    getBannerImages: builder.query({
      query: () => `/rest/V1/banner-api`,
    }),
    getReviews: builder.query({
      query: () => `/rest/V1/reviews/fetch`,
    }),
    getTrendingProducts: builder.query({
      query: () => `/rest/V1/trending-products/4`,
    }),
    getCountries: builder.query({
      query: () => `/rest/V1/list/get-countries`,
    }),
    getAccountInfo: builder.query({
      query: () => `/rest/V1/dashboard/get/customer-details`,
    }),
    createUser: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/customer/signup",
          method: "POST",
          body: values,
        };
      },
    }),
    addItemToWishlist: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/ApiHandle/wishlist/add",
          method: "POST",
          body: values,
        };
      },
    }),
    getCustomerWishlist: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/dashboard/view-wishlist",
          method: "POST",
          body: values,
        };
      },
    }),
    removeWishlistItem: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/dashboard/remove-wishlist",
          method: "POST",
          body: values,
        };
      },
    }),
    addNewAddress: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/dashboard/add-address",
          method: "POST",
          body: values,
        };
      },
    }),
    editAddress: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/dashboard/edit-address",
          method: "POST",
          body: values,
        };
      },
    }),
    deleteAddress: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/dashboard/delete-address",
          method: "POST",
          body: values,
        };
      },
    }),
    reOrderProduct: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/dashboard/re-order",
          method: "POST",
          body: values,
        };
      },
    }),
    addPaymentCard: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/stripe/add-method",
          method: "POST",
          body: values,
        };
      },
    }),
    deletePaymentCard: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/stripe/delete-method",
          method: "POST",
          body: values,
        };
      },
    }),
    forgotPassword: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/customer/forgot-password",
          method: "POST",
          body: values,
        };
      },
    }),
    resetPassword: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/customer/password/reset",
          method: "POST",
          body: values,
        };
      },
    }),
    loginApi: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/custom/login",
          method: "POST",
          body: values,
        };
      },
    }),
    logoutApi: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/custom/logout",
          method: "POST",
          body: values,
        };
      },
    }),
    shareCart: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/ApiHandle/sharecart",
          method: "POST",
          body: values,
        };
      },
    }),
    cartItems: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/customapi/cart/items",
          method: "POST",
          body: values,
        };
      },
    }),
    getSavedPaymentCard: builder.query({
      query: () => `/rest/V1/stripe/fetch/saved-methods`,
    }),
    getBlogs: builder.query({
      query: () => `/rest/V1/mpblog/post`,
    }),
    getBlogById: builder.query({
      query: (id) => `/rest/V1/customapi/post/view/${id}`,
    }),
    getCartCount: builder.query({
      query: () => `/rest/V1/ApiHandle/cart/get-count`,
    }),
    getBreadPayment: builder.query({
      query: () => `/rest/V1/payments/breadpayment`,
    }),
    getSingleProductDetail: builder.query({
      query: (slugOrSku) => `/rest/V1/product-details/${slugOrSku}`,
    }),
    getAllQuestion: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/questionnaire/all-questions",
          method: "POST",
          body: values,
        };
      },
    }),
    getQuestionById: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/questionnaire/publiclink",
          method: "POST",
          body: values,
        };
      },
    }),
    saveQuestionnaireForm: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/questionnaire/save",
          method: "POST",
          body: values,
        };
      },
    }),
    addProductToCompare: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/ApiHandle/compare/add",
          method: "POST",
          body: values,
        };
      },
    }),

    getCartCountApi: builder.query({
      query: () => `/rest/V1/ApiHandle/cart/get-count`,
    }),
    getAllCategories: builder.query({
      query: () => `/rest/V1/custom-categories`,
    }),
    getCompareList: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/ApiHandle/compare/list",
          method: "POST",
          body: values,
        };
      },
    }),
    removeCompare: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/ApiHandle/compare/remove",
          method: "POST",
          body: values,
        };
      },
    }),
    editEmailPassword: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/dashboard/edit/email-pass",
          method: "POST",
          body: values,
        };
      },
    }),
    removeCartProduct: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/ApiHandle/cart/remove",
          method: "POST",
          body: values,
        };
      },
    }),
    contactUsApi: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/customapi/contact-us",
          method: "POST",
          body: values,
        };
      },
    }),
    addMyImages: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/dashboard/add/my-images",
          method: "POST",
          body: values,
        };
      },
    }),
    sendShareCartEmail: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/ApiHandle/sharecart/send-email",
          method: "POST",
          body: values,
        };
      },
    }),
    saveShowroomPartnerApi: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/showroom-partner/save",
          method: "POST",
          body: values,
        };
      },
    }),
    searchProduct: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/products/custom-search",
          method: "POST",
          body: values,
        };
      },
    }),
    unsubscribeApi: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/newsletter/custom-unsubscribe",
          method: "POST",
          body: values,
        };
      },
    }),
    trackOrderApi: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/SearchOrder",
          method: "POST",
          body: values,
        };
      },
    }),
    getShowroomPartnerApi: builder.query({
      query: () => "/rest/V1/showroom-partner/get",
    }),
    getOrdersId: builder.query({
      query: () => `/rest/V1/dashboard/ordersId`,
    }),
    getOrders: builder.query({
      query: () => `/rest/V1/dashboard/orders`,
    }),
    getSkuApi: builder.query({
      query: (val) => `/rest/V1/search/sku/${val}`,
    }),
    getAccountInfo: builder.query({
      query: () => `/rest/V1/dashboard/get/customer-details`,
    }),
    getCustomerAddresses: builder.query({
      query: () => `/rest/V1/dashboard/get-address`,
    }),
    getCartProduct: builder.query({
      query: () => `/rest/V1/ApiHandle/cart/get-item`,
    }),
    breadPaymentApi: builder.query({
      query: () => `/rest/V1/payments/breadpayment`,
    }),
    customRalColorsApi: builder.query({
      query: () => `/rest/V1/customral/colors`,
    }),
    cancelOrderApi: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/CancelOrder",
          method: "POST",
          body: values,
        };
      },
    }),
    getTestimonialApi: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/testimonials/approved",
          method: "POST",
          body: values,
        };
      },
    }),
    addTestimonial: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/testimonials/add",
          method: "POST",
          body: values,
        };
      },
    }),
    NotifyBackInStock: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/customer-notify",
          method: "POST",
          body: values,
        };
      },
    }),
    addProduct: builder.mutation({
      query: (values) => {
        return {
          url: "/rest/V1/ApiHandle/cart/add",
          method: "POST",
          body: values,
        };
      },
    }),
  }),
});

export const { useUnsubscribeApiMutation,useTrackOrderApiMutation, useBreadPaymentApiQuery, useCustomRalColorsApiQuery, useGetCartProductQuery, useAddProductMutation, useGetOrdersQuery, useSearchProductMutation, useGetCustomerAddressesQuery, useRemoveWishlistItemMutation, useGetCustomerWishlistMutation, useGetAccountInfoQuery, useNewsLetterApiMutation, useGetFrequentlyPurchasedQuery, useCategoryFiltersQuery, useSingleCategoryDataMutation, useGetPagesQuery, useGetMyImagesMutation, useGetShowroomPartnerApiQuery, useGetSkuApiQuery, useSaveShowroomPartnerApiMutation, useGetOrdersIdQuery, useGetQuestionByIdMutation, useSendShareCartEmailMutation, useSaveQuestionnaireFormMutation, useAddMyImagesMutation, useContactUsApiMutation, useRemoveCartProductMutation, useGetAllCategoriesQuery, useGetCartCountApiQuery, useEditEmailPasswordMutation, useRemoveCompareMutation, useGetCompareListMutation, useGetAllQuestionMutation, useLogoutApiMutation, useCartItemsMutation, useShareCartMutation, useLoginApiMutation, useResetPasswordMutation, useForgotPasswordMutation, useGetSingleProductDetailQuery, useGetBreadPaymentQuery, useGetCategoryInfoQuery, useGetGalleryVideosQuery, useGetTrainingVideosQuery, useGetCartCountQuery, useGetBlogByIdQuery, useGetBlogsQuery, useDeletePaymentCardMutation, useAddPaymentCardMutation, useGetSavedPaymentCardQuery, useReOrderProductMutation, useAddItemToWishlistMutation, useEditAddressMutation, useDeleteAddressMutation, useGetCountriesQuery, useAddNewAddressMutation, useGetFaqBuyersMutation, useGetTrendingProductsQuery, useGetGalleryImagesMutation, useCreateUserMutation, useGetReviewsQuery, useGetBannerImagesQuery, useAddProductToCompareMutation, useCancelOrderApiMutation, useGetTestimonialApiMutation, useAddTestimonialMutation, useNotifyBackInStockMutation } = apiSlice;
